@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--foreground-rgb: 0, 0, 0;
	--background-start-rgb: 255, 255, 255;
}

body {
	color: rgb(var(--foreground-rgb));
	background: rgb(var(--background-start-rgb));
}

@layer utilities {
	.text-balance {
		text-wrap: balance;
	}
}
